<template>
    <div :style="styles" class="c-SoftPhone" v-if="todisplay" v-show="isvisible">
        <div v-if="!callHSM">
            <span>Error! Missing CoordinatingHSM</span>
        </div>

        <div v-else class="headerbar pa-2">
            <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" :color="phoneColor" :class="phoneClasses">
                        {{phoneIcon}}
                    </v-icon>
                </template>
                <span>{{Translate(phoneIconTooltip)}}</span>
            </v-tooltip>
            <span class="ml-3">
                {{phoneTitle}}
            </span>

            <span style="flex-grow: 1;"></span>

            <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon small v-bind="attrs" v-on="on" @click="toggleDoNotDisturbClick">
                        <v-icon :color="doNotDisturbColor" small>
                            mdi-cancel
                        </v-icon>
                    </v-btn>
                </template>
                <span>Toggle Do-Not-Disturb</span>
            </v-tooltip>

            <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon small v-bind="attrs" v-on="on" @click="toggleConferenceModeClick" :disabled="activeCallList.length > 0">
                        <v-icon color="gray">
                             {{ conferenceMode ? 'mdi-account' : 'mdi-account-group' }}
                        </v-icon>
                    </v-btn>
                </template>
                <span>{{ conferenceMode ? Translate('Switch to Normal') : Translate('Switch to Conference Mode') }}</span>
            </v-tooltip>
        </div>

        <div class="inputbar ma-2" v-if="!conferenceMode">
            <v-form>
                <v-select outlined dense label="From" :menu-props="{offsetY:true, auto:true}" :items="fromList" :value="currentFrom" @change="fromChanged">

                </v-select>
                <v-text-field outlined dense autofocus hide-details clearable
                              ref="searchdial"
                              label="Search / Dial"
                              placeholder="Search / Dial"
                              v-model="dialstring"
                              :append-icon="activeCallList.length > 0 ? 'mdi-dialpad' : undefined"
                              @click:append="showDialpad = !showDialpad"
                              @input="dialstringChanged"
                              @click="dialstringClick"
                              @keydown.enter="dialstringEnter"
                              @keydown.down="dialstringDown"
                              @keydown.up="dialstringUp"
                              >

                </v-text-field>

                <v-menu v-model="showingToList"
                        :position-x="x"
                        :position-y="y"
                        absolute
                        offset-y>
                    <v-list  multiple max-height="400px">
                        <v-list-item-group v-model="toSelected">
                            <v-list-item v-for="(item, index) in filteredToList"
                                        :key="index"
                                        @click="selectToItem(item)"
                                        :title="item.subtitle">
                                <v-list-item-icon>
                                    <v-icon v-text="item.icon" small></v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ item.title }}
                                    </v-list-item-title>

                                    <v-list-item-subtitle>
                                        {{ item.subtitle }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                
                                <v-list-item-content>
                                    <v-btn  v-if="item.contact_id"
                                            color="blue darken-1"
                                            text @click="showDetails($event, item.value)">
                                            Details
                                    </v-btn>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-menu>
            </v-form>
        </div>

        <div class="dialpad ma-2" v-if="!conferenceMode && showDialpad && activeCallList.length > 0">
            <v-text-field outlined hide-details dense class="mb-3" @keydown="dtmfInput" v-model="dtmfHistory">

            </v-text-field>

                <v-row>
                    <v-btn icon x-large @click="senddtmf('1')">
                        <div class="dialpadbutton">
                            <div class="digit">1</div>
                            <div class="alpha" style="opacity: 0;">_</div>
                        </div>
                    </v-btn>

                    <v-btn icon x-large @click="senddtmf('2')">
                        <div class="dialpadbutton">
                            <div class="digit">2</div>
                            <div class="alpha">ABC</div>
                        </div>
                    </v-btn>

                    <v-btn icon x-large @click="senddtmf('3')">
                        <div class="dialpadbutton">
                            <div class="digit">3</div>
                            <div class="alpha">DEF</div>
                        </div>
                    </v-btn>
                </v-row>

                <v-row>
                    <v-btn icon x-large @click="senddtmf('4')">
                        <div class="dialpadbutton">
                            <div class="digit">4</div>
                            <div class="alpha">GHI</div>
                        </div>
                    </v-btn>

                    <v-btn icon x-large @click="senddtmf('5')">
                        <div class="dialpadbutton">
                            <div class="digit">5</div>
                            <div class="alpha">JKL</div>
                        </div>
                    </v-btn>

                    <v-btn icon x-large @click="senddtmf('6')">
                        <div class="dialpadbutton">
                            <div class="digit">6</div>
                            <div class="alpha">MNO</div>
                        </div>
                    </v-btn>
                </v-row>

                <v-row>
                    <v-btn icon x-large @click="senddtmf('7')">
                        <div class="dialpadbutton">
                            <div class="digit">7</div>
                            <div class="alpha">PQRS</div>
                        </div>
                    </v-btn>

                    <v-btn icon x-large @click="senddtmf('8')">
                        <div class="dialpadbutton">
                            <div class="digit">8</div>
                            <div class="alpha">TUV</div>
                        </div>
                    </v-btn>

                    <v-btn icon x-large @click="senddtmf('9')">
                        <div class="dialpadbutton">
                            <div class="digit">9</div>
                            <div class="alpha">WXYZ</div>
                        </div>
                    </v-btn>
                </v-row>

                <v-row>
                    <v-btn icon x-large @click="senddtmf('*')">
                        <div class="dialpadbutton">
                            <div class="digit">*</div>
                            <div class="alpha" style="opacity: 0;">_</div>
                        </div>
                    </v-btn>

                    <v-btn icon x-large @click="senddtmf('0')">
                        <div class="dialpadbutton">
                            <div class="digit">0</div>
                            <div class="alpha">+</div>
                        </div>
                    </v-btn>

                    <v-btn icon x-large @click="senddtmf('#')">
                        <div class="dialpadbutton">
                            <div class="digit">#</div>
                            <div class="alpha" style="opacity: 0;">_</div>
                        </div>
                    </v-btn>
                </v-row>
        </div>

        <div class="dialbar ma-2" v-if="!conferenceMode">
            <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon small v-bind="attrs" v-on="on" class="ml-3" @click="phoneSettingsIconClicked">
                        <v-icon color="blue">
                            mdi-cog
                        </v-icon>
                    </v-btn>
                </template>
                <span>
                    {{Translate('Phone Settings')}}</span>
            </v-tooltip>
            <span style="flex-grow: 1;"></span>
            <v-btn v-if="activeCallList.length < 1" color="primary" @click="callClicked" :disabled="callButtonDisabled">
                Call
            </v-btn>
            <v-item-group class="v-btn-toggle" v-if="activeCallList.length >= 1">
                <v-btn color="primary" @click="holdAndDialClicked">
                    {{Translate('Hold and dial')}}
                </v-btn>
                <v-menu offset-y left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-on="on" v-bind="attrs" color="primary"><v-icon class="white--text">mdi-arrow-down-drop-circle</v-icon></v-btn>
                    </template>
                    <v-list>
                        <v-list-item @click="addCallClicked">
                            <v-list-item-title>
                                {{Translate('Add call (conference)')}}
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="blindTransferClicked">
                            <v-list-item-title>
                                {{Translate('Blind transfer')}}
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-item-group>
        </div>
        <div class="conferencebar ma-2" v-if="conferenceMode">
            <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon small v-bind="attrs" v-on="on" class="ml-3" @click="conferenceSettingsIconClicked">
                        <v-icon color="blue">
                            mdi-cog
                        </v-icon>
                    </v-btn>
                </template>
                <span>{{Translate('Conference Settings')}}</span>
            </v-tooltip>
            <v-btn color="primary" v-if="callHSM.CurrentState == 'Master.Idle'" :disabled="!callHSM.Vars.Mailbox.ConferenceBridge.Enabled" @click="startConferenceClicked">
                {{Translate('Start Conference')}}
            </v-btn>
            <v-btn color="primary" v-if="callHSM.CurrentState == 'Master.Conference' && conferenceStatus.AsModerator" @click="endConferenceClicked">
                {{Translate('End Conference')}}
            </v-btn>
            <v-btn color="primary" v-if="callHSM.CurrentState == 'Master.Idle'" @click="joinConferenceClicked">
                {{Translate('Join Conference')}}
            </v-btn>
            <v-btn color="primary" v-if="callHSM.CurrentState == 'Master.Conference' && !conferenceStatus.AsModerator" @click="leaveConferenceClicked">
                {{Translate('Leave Conference')}}
            </v-btn>
        </div>
        <div class="activecalls ma-2" v-if="generalCallControlsVisible">
            <div class="generalcallcontrols pa-2">
                <v-btn outlined color="primary" @click="muteClicked">
                    {{ callHSM.Vars.ALegHSM.Vars.Muted ? 'Unmute' : 'Mute' }}
                    <v-icon>
                        {{ callHSM.Vars.ALegHSM.Vars.Muted ? 'mdi-microphone-off' : 'mdi-microphone' }}
                    </v-icon>
                </v-btn>

                <span style="flex-grow: 1;"></span>

                <v-btn color="red" class="mr-2" outlined depressed @click="dropOut" v-if="!conferenceMode && activeCallList.length > 1" :disabled="!canDropOut">
                    {{Translate('Drop Out')}}
                    <v-icon class="ml-2">
                        mdi-location-exit
                    </v-icon>
                </v-btn>

                <v-btn color="error" depressed @click="endAllClicked" v-if="!conferenceMode">
                    {{ activeCallList.length > 1 ? Translate('End All') : Translate('End') }}
                    <v-icon class="ml-2">
                        mdi-phone-hangup
                    </v-icon>
                </v-btn>

                <span v-if="conferenceMode && callHSM.Vars.ALegHSM.CurrentState=='Listening.Active' && conferenceStatus.ModeratorName">
                    {{Translate('Moderator:')}} {{ conferenceStatus.ModeratorName }}
                </span>
            </div>

            <div class="callcontrolscontainer">

                <div v-bind:class="{ callcontrols: true, 'pa-4': true, [conferenceMode ? 'call_state_conference' : item.class]: true }"
                     v-for="(item, index) in activeCallList" :key="index">

                    <v-tooltip right v-if="!conferenceMode">
                        <template v-slot:activator="{ on, attrs }">

                            <v-btn :color="item.holdrestore.color" depressed v-bind="attrs" v-on="on" @click="callControlClicked(item.call)">
                                <v-icon>
                                    {{ item.holdrestore.icon }}
                                </v-icon>
                            </v-btn>

                        </template>
                        <span>{{ Translate(item.holdrestore.tooltip) }}</span>
                    </v-tooltip>

                    <v-icon>
                        {{ item.call.Vars.Call && item.call.Vars.Call.Talking && !item.call.Vars.Muted ? 'mdi-volume-high' : 'mdi-volume-low' }}
                    </v-icon>

                    <v-tooltip right v-if="conferenceMode">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="error" icon depressed larger v-bind="attrs" v-on="on" @click="conferenceMemberToggleMute(item.call)">
                                <v-icon color="black" larger>
                                    {{ item.call.Vars.Muted ? 'mdi-microphone-off' : 'mdi-microphone' }}
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>{{Translate('Mute')}}</span>
                    </v-tooltip>

                    <ElapsedTime :starttime="item.StartTime"></ElapsedTime>

                    <div style="display: flex; flex-direction: column; align-items: start;">
                        <span class="from_title">{{ item.title }}</span>
                        <span class="from_subtitle">{{ item.subtitle }}</span>
                    </div>

                    <span style="flex-grow: 1;"></span>

                    <v-tooltip right v-if="conferenceMode && conferenceStatus.AsModerator && item.call.Vars.RaisedHand">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="error" icon depressed v-bind="attrs" v-on="on" @click="item.call.Vars.RaisedHand = false">
                                <v-icon color="green">
                                    mdi mdi-hand-back-right-outline
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>{{Translate("Participant's hand is raised")}}</span>
                    </v-tooltip>

                    <v-tooltip right v-if="activeCallList.length > 1">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="error" icon depressed v-bind="attrs" v-on="on" @click="endOneCall(item.call)">
                                <v-icon>
                                    mdi-phone-hangup
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>{{Translate('Hang up')}}</span>
                    </v-tooltip>

                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon>
                                    mdi-dots-vertical
                                </v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item-group>
                                <v-list-item @click="copySessionID(item.call)">
                                    <v-list-item-content>
                                        <v-list-item-title>Copy Session ID</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-menu>
                </div>

            </div>
        </div>
    
        <div class="alertingbar ma-2" v-if="incomingAlerting">
            <span>{{Translate('You have an incoming call from')}}</span>
            
            <AsyncTextblock :settings="incomingCallFromSettings" class="incomingAlertingStyles"></AsyncTextblock>

            <div class="incomingAlertingButtons">
                <v-btn color="success" @click="acceptIncomingCall">
                    {{Translate('Accept')}}
                    <v-icon class="ml-3">mdi-phone</v-icon>
                </v-btn>

                <v-btn color="error" @click="declineIncomingCall">
                    {{Translate('Decline')}}
                    <v-icon class="ml-3">mdi-phone-missed</v-icon>
                </v-btn>
            </div>
        </div>

        <v-dialog width="735" :value="showJoinConferenceDialog" persistent>
            <v-card>
                <v-app-bar flat color="rgba(0, 0, 0, 0)" style="background-color: var(--v-navigation-base); height: 60px">

                    <v-toolbar-title class="title white--text pl-0">
                        {{Translate('Join Conference')}}
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-btn color="white" icon @click="showJoinConferenceDialog=false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-app-bar>

                <v-card-text class="mt-2 pa-3">
                    <p style="font-size:larger;">
                        {{Translate('Please choose the moderator of the conference to join:')}}
                    </p>

                    <v-form class="mt-3">
                        <v-autocomplete outlined dense autofocus clearable
                                        label="Moderator Name"
                                        :items="moderatorNames"
                                        @focus="loadModeratorNamesFocused"
                                        @keydown.enter="moderatorNamesEnter"
                                        @input="moderatorInput">

                        </v-autocomplete>
                    </v-form>
                </v-card-text>

                <v-card-actions style="justify-content: flex-end;">
                    <v-btn color="primary" @click="moderatorAccept" :disabled="!moderatorUserID">
                        {{Translate('OK')}}
                    </v-btn>
                    <v-btn color="secondary" @click="showJoinConferenceDialog=false">
                        {{Translate('Cancel')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            max-width="450"
            scrollable
            :value="showContactDetails"
            persistent
            v-if="showContactDetails"
        >
            <v-card width="450">
                <v-card-title>
                    <span class="text-h5">Contact Details</span>
                    <v-spacer></v-spacer>
                    <v-btn
                        icon
                        color="secondary"
                        @click="showContactDetails = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-container>
                    <v-card-text>
                            <v-row>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="4"
                                >
                                    <div class="d-flex flex-column" style="font-size: 1rem;">
                                        <span class="text-caption">{{ Translate('First name') }}</span>
                                        <span>{{ selectedContact.first_display }}</span>
                                    </div>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="4"
                                >
                                    <div class="d-flex flex-column" style="font-size: 1rem;">
                                        <span class="text-caption">{{ Translate('Last name') }}</span>
                                        <span>{{ selectedContact.last_display }}</span>
                                    </div>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="4"
                                >
                                    <div class="d-flex flex-column" style="font-size: 1rem;">
                                        <span class="text-caption">{{ Translate('Middle name') }}</span>
                                        <span>{{ selectedContact.middle_display }}</span>
                                    </div>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="4"
                                >
                                    <div class="d-flex flex-column" style="font-size: 1rem;">
                                        <span class="text-caption">{{ Translate('Job title') }}</span>
                                        <span>{{ selectedContact.JobTitle }}</span>
                                    </div>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="4"
                                >
                                    <div class="d-flex flex-column" style="font-size: 1rem;">
                                        <span class="text-caption">{{ Translate('Company') }}</span>
                                        <span>{{ selectedContact.CompanyName }}</span>
                                    </div>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="4"
                                >
                                    <div class="d-flex flex-column" style="font-size: 1rem;">
                                        <span class="text-caption">{{ Translate('Department') }}</span>
                                        <span>{{ selectedContact.Department }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                    </v-card-text>


                    <div>
                        <v-card-title>
                            <span class="text-h5">{{ Translate('Phone Numbers') }}</span>
                        </v-card-title>
                        <v-card-text class="d-flex align-center">
                            <v-radio-group v-model="selectedPhoneNumberValue">
                                <v-radio
                                    v-for="(phoneNumber, index) in selectedContact.PhoneNumbers"
                                    :key="index"
                                    :value="index"
                                >
                                    <template v-slot:label>
                                        <div class="d-flex flex-column" style="font-size: 1rem;">
                                            <span class="text-caption">{{ phoneNumber.type }}</span>
                                            <span>{{ util.formatPhoneNumber(phoneNumber.phonenumber) }}</span>
                                        </div>
                                    </template>
                                </v-radio>
                            </v-radio-group>
                        </v-card-text>
                    </div>
                </v-container>

                <v-card-actions tile>
                    <v-spacer></v-spacer>
                    <!-- <v-btn
                        v-if="!contactSelectable"
                        color="primary"
                        text
                        @click="showContactDetails = false"
                    >
                        {{Translate('Done')}}
                    </v-btn> -->
                    <v-btn
                        color="primary"
                        block
                        @click="selectedContactNumber"
                    >
                        {{Translate('Select')}}
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import Vue from 'vue';
    import BaseComponent from './BaseComponentMixin.jsx';
    import EventBus from '../event-bus.js';
    import utils from '../../Shared/utils.jsx';
    import token from '@/Services/token';
    import careHelpfulFunctions from '@/Application/careHelpfulFunctions.jsx';
    import ElapsedTime from './vuecontrols/elapsedTimer.vue';
    import AsyncTextblock from './vuecontrols/asyncTextblock.vue';

    export default {
        name: 'SoftPhone',
        mixins: [BaseComponent],
        components: {
            ElapsedTime,
            AsyncTextblock,
        },
        data: function () {
            return {
                callHSM: null,
                conferenceMode: false,
                conferenceStatus: {},

                currentFrom: null,
                fromList: [],
                toList: [],
                toContactList: [],
                toSelected: -1,
                dialstring: '',
                searchby: '',
                phoneTitle: 'Phone',

                x: 0,
                y: 0,
                widthToList: '100%',
                showingToList: false,

                showContactDetails: false,
                selectedContact: null,
                contactSelectable: false,
                selectedPhoneNumberValue: 0,

                showDialpad: false,
                dtmfHistory: '',

                bleg_status: {
                    'Interacting.Listening.WaitingForAnswer': {
                        class: 'call_state_dialing',
                        icon: 'mdi-dots-horizontal',
                        color: 'primary',
                        tooltip: '',
                    },
                    'Interacting.Listening.Active.Linked': {
                        class: 'call_state_normal',
                        icon: 'mdi-pause',
                        color: 'success',
                        tooltip: '',
                    },
                    'Interacting.Listening.Active.Unlinked': {
                        class: 'call_state_onhold',
                        icon: 'mdi-play',
                        color: 'warning',
                        tooltip: '',
                    },
                    'Interacting.Dropped': {
                        class: 'call_state_dropped',
                        icon: 'mdi-phone-hangup-outline',
                        color: 'error',
                        tooltip: '',
                    },
                    'Interacting.WrapUp': {
                        class: 'call_state_wrapup',
                        icon: '',
                        color: 'primary',
                        tooltip: '',
                    }
                },

                callerIdName: null,

                showJoinConferenceDialog: false,
                moderatorNames: [],
                moderatorUserID: null,
            }
        },
        //Mounted Replaced with preRenderComplete
        created() {
            if (this.controlData.CoordinatingHSMName)
                this.callHSM = this.Root.ChildControlByName(this.controlData.CoordinatingHSMName);
        },
        computed: {
            util: function () {
                return careHelpfulFunctions;
            },
            styles: function () {
                return {
                    ...utils.resolveStyleHints(this.styleHints, this),
                    ...this.sizeStyle,
                    display: 'flex',
                    flexDirection: 'column'
                }
            },
            test_Aleg_status: function () {
                return (this.callHSM.Vars.ALegHSM.CurrentState || 'NA') + ' ' + (this.callHSM.CurrentState || 'NA');
            },
            phoneIcon: function () {
                if (this.callHSM.Vars.ALegHSM && this.callHSM.Vars.ALegHSM.CurrentState && (this.callHSM.Vars.ALegHSM.CurrentState == 'Dialing' || this.callHSM.Vars.ALegHSM.CurrentState == 'Listening.WaitingForAnswer'))
                    return 'mdi mdi-refresh';
                else
                    return 'mdi-phone';
            },
            phoneColor: function () {
                if (!this.callHSM.Vars.ALegHSM || !this.callHSM.Vars.ALegHSM.CurrentState)
                    return 'grey';
                else if (this.callHSM.Vars.ALegHSM && this.callHSM.Vars.ALegHSM.CurrentState && (this.callHSM.Vars.ALegHSM.CurrentState == 'Listening.Disconnecting' || this.callHSM.Vars.ALegHSM.CurrentState == 'Failed' || this.callHSM.Vars.ALegHSM.CurrentState == 'Dropped'))
                    return 'black';
                else if (this.callHSM.Vars.ALegHSM && this.callHSM.Vars.ALegHSM.CurrentState && (this.callHSM.Vars.ALegHSM.CurrentState.substring(0, 16) == 'Listening.Active' || this.callHSM.Vars.ALegHSM.CurrentState == 'Listening.Transferring'))
                    return 'green';
                else if (this.callHSM.Vars.ALegHSM && this.callHSM.Vars.ALegHSM.CurrentState && (this.callHSM.Vars.ALegHSM.CurrentState == 'Dialing' || this.callHSM.Vars.ALegHSM.CurrentState == 'Listening.WaitingForAnswer'))
                    return 'blue';
                else
                    return '';
            },
            phoneIconTooltip: function () {
                if (!this.callHSM.Vars.ALegHSM || !this.callHSM.Vars.ALegHSM.CurrentState)
                    return 'Soft phone disconnected';
                else if (this.callHSM.Vars.ALegHSM && this.callHSM.Vars.ALegHSM.CurrentState && (this.callHSM.Vars.ALegHSM.CurrentState == 'Listening.Disconnecting' || this.callHSM.Vars.ALegHSM.CurrentState == 'Failed' || this.callHSM.Vars.ALegHSM.CurrentState == 'Dropped'))
                    return 'Soft phone disconnecting...';
                else if (this.callHSM.Vars.ALegHSM && this.callHSM.Vars.ALegHSM.CurrentState && (this.callHSM.Vars.ALegHSM.CurrentState.substring(0, 16) == 'Listening.Active' || this.callHSM.Vars.ALegHSM.CurrentState == 'Listening.Transferring'))
                    return 'Soft phone connected';
                else if (this.callHSM.Vars.ALegHSM && this.callHSM.Vars.ALegHSM.CurrentState && (this.callHSM.Vars.ALegHSM.CurrentState == 'Dialing' || this.callHSM.Vars.ALegHSM.CurrentState == 'Listening.WaitingForAnswer'))
                    return 'Soft phone dialing...';
                else
                    return 'Soft phone disconnected';
            },
            phoneClasses: function () {
                if (this.callHSM.Vars.ALegHSM && this.callHSM.Vars.ALegHSM.CurrentState && (this.callHSM.Vars.ALegHSM.CurrentState == 'Dialing' || this.callHSM.Vars.ALegHSM.CurrentState == 'Listening.WaitingForAnswer'))
                    return 'mdi-animate-spin';
                else
                    return null;
            },

            doNotDisturbColor: function () {
                return this.callHSM.Vars.DoNotDisturb ? 'red' : 'gray';
            },


            callButtonDisabled: function () {
                return this.callHSM.CurrentState == 'Master.Interactive' || this.callHSM.CurrentState == 'Disabled';
            },

            generalCallControlsVisible: function () {
                return this.callHSM.Vars && this.callHSM.Vars.ALegHSM && this.callHSM.Vars.ALegHSM.CurrentState == 'Listening.Active';
            },

            filteredToList: function () {
                if (this.searchby) {
                    var searchVal = this.searchby.toLowerCase();
                    var searchList = this.toList.filter(a => a.searchby && a.searchby.toLowerCase().includes(searchVal));
                    searchList = searchList.concat(this.toContactList);
                    return searchList;
                } else {
                    return this.toList;
                }
            },

            activeCallList: function () {
                if (this.callHSM && this.callHSM.Vars && this.callHSM.Vars.BLegHSMs) {
                    const blegs = this.callHSM.Vars.BLegHSMs;
                    const items = Object.keys(blegs)
                        .map(k => blegs[k])
                        .map(b => ({
                            CurrentState: b.CurrentState,

                            StartTime: (b.Vars.Call && b.Vars.Call.StartTime) ? Date.parse(b.Vars.Call.StartTime) : new Date(),

                            title: this.getTitle(b),
                            subtitle: this.getSubtitle(b),
                            original: this.getOriginal(b),
                            class: this.bleg_status[b.CurrentState] ? this.bleg_status[b.CurrentState].class : '',
                            holdrestore: {
                                icon: this.bleg_status[b.CurrentState] ? this.bleg_status[b.CurrentState].icon : '',
                                color: this.bleg_status[b.CurrentState] ? this.bleg_status[b.CurrentState].color : '',
                                tooltip: this.bleg_status[b.CurrentState] ? this.bleg_status[b.CurrentState].tooltip : '',
                            },

                            call: b,
                        }));

                    return items;
                }
                else
                    return [];

                return [
                    {
                        SessionId: 'abc',
                        State: 'Linked',
                        StartTime: new Date().toISOString(),

                        title: 'Lehi Polycom',
                        subtitle: '(801) 727-3538',
                        class: 'call_state_normal',
                        holdrestore: {
                            icon: 'mdi-pause',
                            color: 'success',
                            tooltip: 'Click to Hold',
                        }
                    },
                    {
                        SessionId: 'xyz',
                        State: 'Unlinked',
                        StartTime: new Date().toISOString(),

                        title: 'David Peterson',
                        subtitle: '(801) 557-9028',
                        class: 'call_state_onhold',
                        holdrestore: {
                            icon: 'mdi-play',
                            color: 'warning',
                            tooltip: 'Click to Restore',
                        }
                    }
                ];
            },

            agentInfo: function () {
                if (this.callHSM.Vars.ALegHSM && this.callHSM.Vars.ALegHSM.CurrentState && this.callHSM.Vars.ALegHSM.CurrentState.substring(0, 16) == 'Listening.Active')
                    return this.callHSM.Vars.ALegHSM.Vars?.Call?.SessionId;
                else
                    return '';
            },

            incomingAlerting: function () {
                return this.callHSM.Vars.ALegHSM && this.callHSM.Vars.ALegHSM.Vars && this.callHSM.Vars.ALegHSM.ParamByName('Direction', false, true) == 'IB' && (this.callHSM.Vars.ALegHSM.CurrentState == 'Listening.Alerting' || this.callHSM.Vars.ALegHSM.CurrentState == 'Listening.WaitingForAnswer');
            },
            incomingCallFromSettings: function () {
                const settings = {
                    parent: this,
                    default_text: '',
                    url: null,
                    modelas: 'data',
                    result_expression: null,
                };

                if (this.callHSM.Vars.IncomingCall.CallerId && this.callHSM.Vars.IncomingCall.CallerId.substr(0, 4) !== 'USER') {
                    settings.default_text = this.f_phoneNumber((this.callHSM.Vars.IncomingCall.CallerName || this.callHSM.Vars.IncomingCall.CallerId));
                }
                else if (this.callHSM.Vars.IncomingCall.CallerId && this.callHSM.Vars.IncomingCall.CallerId.substr(0, 4) == 'USER') {
                    settings.default_text = 'Reading...';
                    settings.url = `Apps/Common/UserPhoneContact/fullname?PhoneNumber=${this.callHSM.Vars.IncomingCall.CallerId}`;
                    settings.result_expression = 'data != "NotFound" ? data : "Internal User"';
                }

                return settings;
            },

            canDropOut: function() {
                return !this.activeCallList.some(b => b.CurrentState == 'Interacting.Listening.WaitingForAnswer');
            },
        },
        methods: {
            preRenderComplete() {
                this.finishRenderHandler(this);
                this.loadFromList();
                this.loadToList();
                this.loadUserExtension();
            },
            async loadFromList() {
                this.currentFrom = this.System.LocalStorage('pbx-phone-from');

                const data = await utils.api.get('Apps/Common/v2/ListMyCallerIDs');
                this.fromList = data.map(a => ({
                    text: a.Name,
                    value: a.Value,
                }));

                if (!this.currentFrom && this.fromList.length > 0)
                    this.currentFrom = this.fromList[0].value;
            },
            fromChanged(value) {
                this.currentFrom = value;
                this.System.LocalStorage('pbx-phone-from', value);
            },
            getToSubTitle(data) {
                let subTitle = '';

                if (data.isuser) {
                    subTitle += this.Translate('Internal User')
                } else if (data.integrationName) {
                    subTitle += this.Translate(data.subtitle) + ' - ' + careHelpfulFunctions.formatPhoneNumber(data.phonenumber)
                } else {
                    subTitle += careHelpfulFunctions.formatPhoneNumber((data.phonenumber || data.subtitle))
                }

                if (data.last_called) {
                    subTitle += ((data.phonenumber || data.subtitle) ? ' - ' : '') + ' ' + this.f_date(data.last_called, 'medium');
                }

                return subTitle;

            },
            async loadToList() {
                const data = await utils.api.get('Apps/Common/LastCallsDialed');
                this.toList = data.map(a => ({
                    text: a.isuser ? (a.name || a.title) : (a.phonenumber || a.subtitle || a.title),
                    title: careHelpfulFunctions.formatPhoneNumber(a.name || a.title),
                    subtitle: this.getToSubTitle(a),
                    value: a,
                    icon: a.isuser ? 'mdi mdi-account' : (a.icon || 'mdi mdi-phone'),
                }));
            },
            fromMenuInput(value) {
                this.fromMenuVisible = value;
            },
            async loadUserExtension() {
                const data = await utils.api.get('Apps/Common/HomeData');
                if (data)
                    this.phoneTitle = `${data.FullName}${data.UserExtension ? ` x.${data.UserExtension}` : ''}`;
            },

            toggleDoNotDisturbClick(e) {
                this.callHSM.sendEvent('ToggleDND', undefined, undefined, { });
            },
            toggleConferenceModeClick(e) {
                this.conferenceMode = !this.conferenceMode;
            },

            getTitle(b) {
                let title = '';
                if (!b.Vars.Call)
                    return title;

                if (b.Vars.Call.Destination.substring(0, 4) == 'USER')
                    title = b.Vars.Call.CallerDisplayName;
                else
                    title = careHelpfulFunctions.formatPhoneNumber(b.Vars.Call.CallerDisplayName);

                return title;

                // If   : b.Vars.Call.Outbound && b.Vars.Call.Destination.substring(0,4)!=='USER' && b.Vars.Call.Destination.trim() !== b.Vars.Call.CallerDisplayName.trim()
                // Then : b.Vars.Call.Destination | phoneNumber
            },
            getSubtitle(b) {
                if (!b.Vars.Call)
                    return '';

                if (b.Vars.Call.Outbound && b.Vars.Call.Destination?.substring(0, 4) !== 'USER' && b.Vars.Call.Destination?.trim() !== b.Vars.Call.CallerDisplayName?.trim())
                    return this.f_phoneNumber(b.Vars.Call.Destination);
                else if (!b.Vars.Call.Outbound && b.Vars.Call.CallerId?.substring(0, 4) !== 'USER' && b.Vars.Call.CallerId?.trim() !== b.Vars.Call.CallerDisplayName?.trim())
                    return this.f_phoneNumber(b.Vars.Call.CallerId);
                else if ((b.Vars.Call.Outbound && b.Vars.Call.Destination?.substring(0, 4) == 'USER') || (!b.Vars.Call.Outbound && b.Vars.Call.CallerId?.substring(0, 4) == 'USER'))
                    return this.Translate('Internal User');
                else
                    return '';
            },
            getOriginal(b) {
                if (!b.Vars.Call)
                    return '';

                if (b.Vars.Call.Payload && b.Vars.Call.Payload.originaldirection == 'inbound' && b.Vars.Call.Payload.originalcallerid?.substring(0, 4) !== 'USER')
                    return this.f_phoneNumber(b.Vars.Call.Payload.originalcallerid);
                else if (b.Vars.Call.Payload && b.Vars.Call.Payload.originaldirection == 'outbound' && b.Vars.Call.Payload.originalcallerid?.substring(0, 4) !== 'USER')
                    return this.f_phoneNumber(b.Vars.Call.Payload.originaldestination);
                else
                    return '';
            },
            showDetails(event, contact) {
                event.stopPropagation();
                this.selectedContact = contact;
                this.contactSelectable = contact.PhoneNumbers.length > 1;
                this.showContactDetails = true;
                this.selectedPhoneNumberValue = 0;
            },
            selectedContactNumber() {
                if (this.selectedPhoneNumberValue != -1) {
                    this.dialstring = this.selectedContact.PhoneNumbers[this.selectedPhoneNumberValue].phonenumber;
                    this.toSelected = -1;
                }

                this.showContactDetails = false;
            },
            selectToItem(value) {
                this.dialstring = value.value.isuser ? value.title : value.contact_id ? value.phonenumber : (value.text || value.title);
                this.toSelected = -1;
            },
            async showToList(items) {
                this.showingToList = false;
                const rect = this.$refs.searchdial.$el.getBoundingClientRect();

                this.x = rect.left;
                this.y = rect.bottom;

                if (items)
                    this.toList = items;
                else
                    await this.loadToList();

                this.$nextTick(() => {
                    this.showingToList = true
                });
            },
            dialstringClick(e) {
                e.preventDefault();
                this.searchby = '';
                this.showToList();
            },
            async dialstringChanged() {
                if (this.dialstring && this.dialstring.length > 1) {
                    try {
                        var cl = [];
                        var simpleSearch = await utils.api.get(`Apps/Contacts2/Contact/SearchSimple?SearchText=${this.dialstring}&AdditionalInfo=${JSON.stringify(["PhoneNumber","CompanyName","JobTitle","Department"])}`);
                        var formattedInputNumber = careHelpfulFunctions.formatPhoneNumber(this.dialstring);
                        
                        simpleSearch.forEach((contact) => {
                            if (contact.PhoneNumbers.length)
                            {
                                var searchTerm = this.dialString;
                                var correctPn = contact.PhoneNumbers.find(pn => careHelpfulFunctions.formatPhoneNumber(pn.phonenumber) == formattedInputNumber || (pn.phonenumber.includes(searchTerm) && searchTerm.length > 6));
                                var contactLabel = `${contact.first_display || ''} ${contact.last_display || ''}`;
                                cl.push( 
                                {
                                    text: contactLabel,
                                    title: contactLabel,
                                    subtitle: `${contact.PhoneNumbers.map(function(ph) { return careHelpfulFunctions.formatPhoneNumber(ph.phonenumber); }).join(", ")}`,
                                    value: contact,
                                    icon: 'mdi mdi-card-account-details',
                                    searchby: `${contactLabel} (${contact.PhoneNumbers.map(function(ph) { return careHelpfulFunctions.formatPhoneNumber(ph.phonenumber); }).join(", ")})`,
                                    contact_id: contact.contact_id,
                                    phonenumber: correctPn != null ? careHelpfulFunctions.formatPhoneNumber(correctPn.phoneNumber) : careHelpfulFunctions.formatPhoneNumber(contact.PhoneNumbers[0].phonenumber)
                                });
                            }
                        });

                        this.toContactList = cl;
                    }
                    catch(err) {
                        console.log(err);
                    }
                }

                if (this.dialstring && this.dialstring.length <= 1 && this.showingToList) {
                    this.showingToList = false;
                    this.searchby = '';
                }
                else if (this.dialstring && this.dialstring.length > 1 && !this.showingToList) {
                    let items = this.callHSM.Vars.AutoCompleteItems;

                    items = items.map(a => ({
                        text: a.isuser ? a.userid : a.title,
                        title: careHelpfulFunctions.formatPhoneNumber(a.title),
                        subtitle: a.subtitle,
                        value: a,
                        icon: a.icon,
                        searchby: a.searchby,
                    }));
                    
                    this.searchby = this.dialstring;
                    this.showToList(items);
                    if (items.length > 0)
                        this.toSelected = 0;
                }
                else if (this.dialstring && this.dialstring.length > 1 && this.showingToList) {
                    this.searchby = this.dialstring;
                    this.toSelected = 0;
                }
            },
            dialstringEnter() {
                if (this.dialstring && (!this.showingToList || this.filteredToList.length < 1))
                    this.dialPhone();
                else if (this.showingToList && this.toSelected >= 0 && this.filteredToList.length > 0 && this.toSelected < this.filteredToList.length) {
                    const choice = this.filteredToList[this.toSelected];
                    this.dialstring = choice.value.isuser ? choice.title : (choice.text || choice.title);
                    this.showingToList = false;
                }
            },
            dialstringDown() {
                if (this.showingToList && this.toList.length > 0) {
                    this.toSelected++;
                    if (this.toSelected >= this.toList.length)
                        this.toSelected = 0;
                }
            },
            dialstringUp() {
                if (this.showingToList && this.toList.length > 0) {
                    this.toSelected--;
                    if (this.toSelected < 0)
                        this.toSelected = this.toList.length - 1;
                }
            },

            callClicked() {
                this.showingToList = false;
                this.dtmfHistory = '';
                this.dialPhone();
            },
            holdAndDialClicked() {
                this.showingToList = false;
                this.dialPhone('DialPrivate');
            },
            addCallClicked() {
                this.dialPhone('Dial');
            },
            blindTransferClicked() {
                this.dtmfHistory = '';
                this.dialPhone('TransferTo');
            },
            async dialPhone(method) {
                if (!this.dialstring) {
                    if (this.toList.length > 0)
                        this.dialstring = this.toList[0].value.isuser ? this.toList[0].name : this.toList[0].value.phonenumber;
                }
                else {
                    let queryString = "";
                    queryString += `DialString=${encodeURIComponent(this.dialstring)}`;
                    queryString += `&From=${this.currentFrom}`;

                    try {
                        const num = await utils.api.get(`Apps/Common/ResolveDialString?${queryString}`);
                        if (!num || !num.Success) {
                            EventBus.$emit('v-toast', {
                                type: 'warning',
                                name: 'dialerror',
                                text: this.Translate('Dial Failed'),
                                subtext: `${this.Translate('The number entered is not a valid phone number or user')}: ${this.dialstring}`,
                                context: this,
                            });
                            return;
                        }
                        this.callHSM.sendEvent(method || 'Dial', undefined, undefined, { DialInfo: num });

                        this.dialstring = '';
                    }
                    catch (err) {
                        EventBus.$emit('v-toast', {
                            type: 'error',
                            name: 'dialerror',
                            text: this.Translate('Dial Failed'),
                            subtext: `${this.Translate('An error occurred attempting to dial')} '${this.dialstring}': ${JSON.stringify(err)}`,
                            context: this,
                        });
                    }
                }
            },
            endAllClicked() {
                this.dtmfHistory = '';
                this.callHSM.sendEvent('HangUpAll', undefined, undefined, { });
            },
            dropOut() {
                this.dtmfHistory = '';
                this.callHSM.sendEvent('Transfer', undefined, undefined, {});
            },
            muteClicked() {
                const aleg = this.callHSM.Vars.ALegHSM;
                if (!aleg) return;

                if (aleg.CurrentState == 'Listening.Active') {
                    if (!aleg.Vars.Muted)
                        aleg.sendEvent('DoMute', undefined, undefined, {});
                    else
                        aleg.sendEvent('DoUnmute', undefined, undefined, {});
                }
            },
            callControlClicked(call) {
                switch (call.CurrentState) {
                    case 'Interacting.Listening.Active.Linked':
                        call.sendEvent('Hold', undefined, undefined, {});
                        break;

                    case 'Interacting.Listening.Active.Unlinked':
                        call.sendEvent('Restore', undefined, undefined, {});
                        break;
                }
            },
            endOneCall(call) {
                this.dtmfHistory = '';
                call.sendEvent('HangUp', undefined, undefined, {});
            },
            copySessionID(call) {
                navigator.clipboard.writeText(call.Vars.Call.SessionId);
            },

            alphaToDTMF(alpha) {
                if (alpha.length > 1)
                    return '';
                else if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '#'].includes(alpha))
                    return alpha;
                else if ((alpha >= 'a' && alpha <= 'c') || (alpha >= 'A' && alpha <= 'C'))
                    return '2';
                else if ((alpha >= 'd' && alpha <= 'f') || (alpha >= 'D' && alpha <= 'F'))
                    return '3';
                else if ((alpha >= 'g' && alpha <= 'i') || (alpha >= 'G' && alpha <= 'I'))
                    return '4';
                else if ((alpha >= 'j' && alpha <= 'l') || (alpha >= 'J' && alpha <= 'L'))
                    return '5';
                else if ((alpha >= 'm' && alpha <= 'o') || (alpha >= 'M' && alpha <= 'O'))
                    return '6';
                else if ((alpha >= 'p' && alpha <= 's') || (alpha >= 'P' && alpha <= 'S'))
                    return '7';
                else if ((alpha >= 't' && alpha <= 'v') || (alpha >= 'T' && alpha <= 'V'))
                    return '8';
                else if ((alpha >= 'w' && alpha <= 'z') || (alpha >= 'W' && alpha <= 'Z'))
                    return '9';
                else if (alpha == '+')
                    return '0';
                else
                    return '';
            },
            dtmfInput(alpha) {
                // Called when a key is pressed when inside the keypad text field

                alpha.preventDefault();
                const digit = this.alphaToDTMF(alpha.key);
                if (digit)
                    this.senddtmf(digit);
            },
            senddtmf(digit) {
                const aleg = this.callHSM.Vars.ALegHSM;
                if (!aleg) return;

                aleg.sendEvent('DoDTMF', undefined, undefined, digit);
                this.dtmfHistory += digit;
            },

            startConferenceClicked(e) {
                this.conferenceStatus.AsModerator = true;
                this.conferenceStatus.ModeratorName = 'Self';
                this.callHSM.sendEvent('Conference', undefined, undefined, { AsModerator: true, ModeratorID: token.UserID() });
            },
            endConferenceClicked(e) {
                this.callHSM.sendEvent('HangUpAll', undefined, undefined, { });
            },
            joinConferenceClicked(e) {
                this.showJoinConferenceDialog = true;
            },
            leaveConferenceClicked(e) {
                this.callHSM.sendEvent('Leave', undefined, undefined, {});
            },
            conferenceMemberToggleMute(call) {
                if (!call.Vars.Muted)
                {
                    call.sendEvent('DoMute');
                    call.Vars.Call.Talking = false;
                }
                else
                    call.sendEvent('DoUnmute');
            },

            async lookupCallerIdName(callerid) {
                try {
                    const data = await utils.api.get(`Apps/Common/UserPhoneContact/fullname?PhoneNumber=${callerid}`);
                    this.callerIdName = data != 'NotFound' ? data : this.Translate('Internal User');
                }
                catch (e) {
                    utils.warn(`SoftPhone.lookupCallerIdName(callerid:${callerid}) - exception`, e);
                    this.callerIdName = this.Translate('Internal User');
                }
            },
            acceptIncomingCall(e) {
                const aleg = this.callHSM.Vars.ALegHSM;
                if (aleg)
                    aleg.sendEvent('Accept');
            },
            declineIncomingCall(e) {
                const aleg = this.callHSM.Vars.ALegHSM;
                if (aleg)
                    aleg.sendEvent('Reject');
            },

            phoneSettingsIconClicked(e) {
                utils.executeAndCompileAllActions(this.controlData.PhoneSettingsActions, null, this);
            },
            conferenceSettingsIconClicked(e) {
                utils.executeAndCompileAllActions(this.controlData.ConferenceSettingsActions, null, this);
            },

            loadModeratorNamesFocused(e) {
                this.loadModeratorNames();
            },
            async loadModeratorNames() {
                const data = await utils.api.get('Apps/GetCustomerUsers');
                if (data)
                    this.moderatorNames = data.map(a => ({
                        text: a.FullName,
                        value: a.UserID,
                    }));
            },
            moderatorNamesEnter(e) {
                e.preventDefault();
                e.cancelBubble = true;
                e.stopPropagation();
                // Prevent Enter from causing the page to reload (really!)
            },
            moderatorInput(value) {
                this.moderatorUserID = value;
            },
            moderatorAccept() {
                if (this.moderatorUserID) {
                    const name = this.moderatorNames.find(a => a.value == this.moderatorUserID);
                    if (name) {
                        this.conferenceStatus.ModeratorName = name.text;
                        this.conferenceStatus.ModeratorID = this.moderatorUserID;
                        this.conferenceStatus.AsModerator = false;
                        this.callHSM.sendEvent('Conference', undefined, undefined, this.conferenceStatus);

                        this.showJoinConferenceDialog = false;
                    }
                }
            },
        },
    };
</script>

<style scoped>
    .headerbar {
        display: flex;
        flex-direction: row;
    }

    .inputbar {

    }

    .dialbar {
        display: flex;
    }

    .dialpad {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .dialpadbutton {
        font-size: 1.3em;
    }

    .digit {
        text-align: center;
        margin-bottom: 0.2em;
        font-size: 1em;
        color: #007bff;
    }

    .alpha {
        text-align: center;
        font-size: .5em;
        margin-top: -0.75em;
    }

    .conferencebar {
        display: flex;
        justify-content: space-evenly;
    }

    .activecalls {
        display: flex;
        flex-direction: column;
    }

    .generalcallcontrols {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-width: 1px;
        border-bottom-color: #a4a3a3;
        border-bottom-style: solid;
        background-color: #d9d9d9;
        display: flex;
        align-items: center;
    }

    .callcontrolscontainer {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    .callcontrols {
        display: flex;
        align-items: center;
        gap: 20px;
    }

    .alertingbar {
        display: flex;
        flex-direction: column;
        background-color: #feeeee;
        padding: 15px;
        border-radius: 5px;
        animation: blink-animation-alerting .8s linear infinite;
    }
    @keyframes blink-animation-alerting {
        to {
            background-color: white;
        }
    }

    .incomingAlertingButtons {
        display: flex;
        justify-content: center;
        gap: 20px;
    }

    .incomingAlertingStyles {
        font-size: larger;
        margin: 15px;
    }

    .from_title {
        font-size: inherit;
        font-weight: bolder;
    }

    .from_subtitle {
        font-size: smaller;
    }

    .call_state_dialing {
        background-color: #a7e2a5;
        animation: blink-animation-dialing .8s linear infinite;
    }
    @keyframes blink-animation-dialing {
        to {
            background-color: #8890e4;
        }
    }

    .call_state_normal {
        background-color: #a7e2a5;
    }

    .call_state_onhold {
        background-color: #efe194;
    }

    .call_state_dropped {
        background-color: #efe194;
        animation: blink-animation-dropped .8s linear infinite;
    }
    @keyframes blink-animation-dropped {
        to {
            /*visibility: hidden;*/
            background-color: #e48888;
        }
    }

    .call_state_conference {
        background-color: #f1f2ff;
    }

</style>
